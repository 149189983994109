import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import { CenteredContent } from "../components/shared/centered-content"
import { Card } from "../components/shared/card"
import { Header, Description } from "../components/shared/text"
import { colors } from "../theme"

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

const AboutTemplate = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data // data.markdownRemark.html holds our content for this page

  return (
    <>
      {nodes.length &&
        nodes.map((node, idx) => (
          <CenteredContent key={node.id}>
            <Stack>
              <CenteredContent>
                <Header
                  style={{
                    textAlign: "center",
                    color: idx % 2 ? colors.tallyYellow : undefined,
                  }}
                >
                  {node.frontmatter.title}
                </Header>
              </CenteredContent>
              <Card
                color={idx % 2 ? colors.tallyYellow : colors.tallyWhite}
                style={{
                  maxWidth: "800px",
                  height: "auto",
                  margin: "1rem 1rem 1.5rem 1rem",
                  backgroundColor: idx % 2 ? colors.tallyGreyInset : undefined,
                }}
              >
                <div
                  style={{ fontSize: "1.2em", fontWeight: "500" }}
                  dangerouslySetInnerHTML={{ __html: node.html }}
                />
              </Card>
            </Stack>
          </CenteredContent>
        ))}
    </>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query AboutTemplate {
        allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/about/i" } } }
        ) {
          nodes {
            frontmatter {
              title
            }
            html
          }
        }
      }
    `}
    render={data => <AboutTemplate data={data} />}
  />
)
